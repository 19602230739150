// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-components-lottie-readme-mdx": () => import("./../../../../docs/components/Lottie/README.mdx" /* webpackChunkName: "component---docs-components-lottie-readme-mdx" */),
  "component---docs-hooks-use-lottie-interactivity-readme-mdx": () => import("./../../../../docs/hooks/useLottieInteractivity/README.mdx" /* webpackChunkName: "component---docs-hooks-use-lottie-interactivity-readme-mdx" */),
  "component---docs-hooks-use-lottie-readme-mdx": () => import("./../../../../docs/hooks/useLottie/README.mdx" /* webpackChunkName: "component---docs-hooks-use-lottie-readme-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

