import { Playground } from "docz";
import UseInteractivityBasic from "../../../../../docs/hooks/useLottieInteractivity/UseInteractivityBasic.js";
import ScrollWithOffset from "../../../../../docs/hooks/useLottieInteractivity/ScrollWithOffset.js";
import ScrollWithOffsetAndLoop from "../../../../../docs/hooks/useLottieInteractivity/ScrollWithOffsetAndLoop.js";
import PlaySegmentsOnHover from "../../../../../docs/hooks/useLottieInteractivity/PlaySegmentsOnHover.js";
import CursorDiagonalSync from "../../../../../docs/hooks/useLottieInteractivity/CursorDiagonalSync.js";
import CursorHorizontalSync from "../../../../../docs/hooks/useLottieInteractivity/CursorHorizontalSync.js";
import * as React from 'react';
export default {
  Playground,
  UseInteractivityBasic,
  ScrollWithOffset,
  ScrollWithOffsetAndLoop,
  PlaySegmentsOnHover,
  CursorDiagonalSync,
  CursorHorizontalSync,
  React
};